import React from "react";

const AppButton = ({ children, className, onClick }, props) => {
  return (
    <button
      className={`bg-[#262626] rounded-[8px] text-white h-[40px] md:h-[54px] px-[10px] md:px-[20px] text-[12px] md:text-[16px] ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default AppButton;
