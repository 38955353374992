import stat1 from "../assets/icons/1.svg";
import stat2 from "../assets/icons/2.svg";
import stat3 from "../assets/icons/3.svg";
import stat4 from "../assets/icons/4.svg";

export const stats = [
  { icon: stat3, title: "decrease in wasted resources and revenue" },
  {
    icon: stat4,
    title: "Less inefficiencies in processes and business visiblity",
  },
  { icon: stat1, title: "For increasing data driven efficiency" },
  { icon: stat2, title: "Revenue increase from data driven processes efficiency " },

];

export const reviews = [
  {
    title: "Most Frequently Used App",
    name: "Dr RTL",
    text: "As a professor, an author, and a consultant, maintaining balance and focus are key to me. A full night sleeping is pretty important, too. I find myself using this app multiple times throughout the day to help with my productivity and at night to help with peaceful sleeping. It has become an critical component for helping maintain my productivity levels.",
  },
  {
    title: "Must have for ADD/ADHD",
    name: "PandaBear226",
    text: `I initially got this for background noise while working but now it's one of my most used apps. If you have ADD you know the struggle of hating silence but also finding most music/podcasts to be too distracting or hard to listen to. I used to listen to classical music but this is SO much better - just set it and forget it and it's so peaceful and pleasant to have in the background. Now I use it to sleep, alarm wake up, chores, my morning/evening commute - I recommended it to my therapist and she loved it so much she is now recommending to her other patients! Can't thank the developers enough, it's worth every penny!`,
  },
  {
    title: "",
    name: "DogRaptor87",
    text: `I absolutely love this app I've been using this app for the last 5 years, to help me get better sleep at night. The more they improve this app, the more I love it. “AI lullaby sets me off to sleep with gentle, soothing tones that help blend the background noise into something a little softer. It's my most used app, and my most important app. Thanks Endel :)`,
  },
  {
    title: "",
    name: "Dakoda88",
    text: `I have never write a review and felt compelled to tell you this app is incredible. After the first two nights of sleeping with Endel I woke up more calm and remained that way for a lot of the day. The hibernation setting is great for chilling at night before bed. Then switch to the sleep setting. It is really weird to me that this is able to have such an impact on my actual life just using sound. 🤩`,
  },
  {
    title: "",
    name: "moonpop",
    text: `I am addicted. Can't sleep right without it.`,
  },
  {
    title: "Helps with my ADHD brain and insomnia",
    name: "wanbunga",
    text: `All I can say is - wow. I used the app for the first time last night after really struggling to sleep, saw the ad and downloaded it (I usually ignore ads but for some reason the ad on this app really caught my pique). I love how minimal but unique the application is. The moving patterns are beautiful as well. I managed to sleep immediately yesterday and I actually played it throughout the night and had a wonderful night sleep. It helped calm my racing thoughts at night and I was surprised at how easy it was to fall asleep.`,
  },
  {
    title: "I'm speechless",
    name: "VelvetBlackShow",
    text: `I'm diagnosed with borderline insomnia major depression etc Nothing really helps me to relax focus clear my mind or sleep. But this app is making me more than happy. I'm so grateful thank you so much guys!!`,
  },
  {
    title: "Most Frequently Used App",
    name: "Dr RTL",
    text: "As a professor, an author, and a consultant, maintaining balance and focus are key to me. A full night sleeping is pretty important, too. I find myself using this app multiple times throughout the day to help with my productivity and at night to help with peaceful sleeping. It has become an critical component for helping maintain my productivity levels.",
  },
  {
    title: "Must have for ADD/ADHD",
    name: "PandaBear226",
    text: `I initially got this for background noise while working but now it's one of my most used apps. If you have ADD you know the struggle of hating silence but also finding most music/podcasts to be too distracting or hard to listen to. I used to listen to classical music but this is SO much better - just set it and forget it and it's so peaceful and pleasant to have in the background. Now I use it to sleep, alarm wake up, chores, my morning/evening commute - I recommended it to my therapist and she loved it so much she is now recommending to her other patients! Can't thank the developers enough, it's worth every penny!`,
  },
  {
    title: "",
    name: "DogRaptor87",
    text: `I absolutely love this app I've been using this app for the last 5 years, to help me get better sleep at night. The more they improve this app, the more I love it. “AI lullaby sets me off to sleep with gentle, soothing tones that help blend the background noise into something a little softer. It's my most used app, and my most important app. Thanks Endel :)`,
  },
  {
    title: "",
    name: "Dakoda88",
    text: `I have never write a review and felt compelled to tell you this app is incredible. After the first two nights of sleeping with Endel I woke up more calm and remained that way for a lot of the day. The hibernation setting is great for chilling at night before bed. Then switch to the sleep setting. It is really weird to me that this is able to have such an impact on my actual life just using sound. 🤩`,
  },
  {
    title: "",
    name: "moonpop",
    text: `I am addicted. Can't sleep right without it.`,
  },
  {
    title: "Helps with my ADHD brain and insomnia",
    name: "wanbunga",
    text: `All I can say is - wow. I used the app for the first time last night after really struggling to sleep, saw the ad and downloaded it (I usually ignore ads but for some reason the ad on this app really caught my pique). I love how minimal but unique the application is. The moving patterns are beautiful as well. I managed to sleep immediately yesterday and I actually played it throughout the night and had a wonderful night sleep. It helped calm my racing thoughts at night and I was surprised at how easy it was to fall asleep.`,
  },
  {
    title: "I'm speechless",
    name: "VelvetBlackShow",
    text: `I'm diagnosed with borderline insomnia major depression etc Nothing really helps me to relax focus clear my mind or sleep. But this app is making me more than happy. I'm so grateful thank you so much guys!!`,
  },
];

export const playlist = [
  {
    title: "International Exposure",
    text: "Get your music heard on a global scale. Our competition attracts industry professionals, influencers, and music lovers from around the world",
  },
  {
    title: "Career Boost",
    text: "Win prizes that can launch your music career, including studio time, music video production, mentorship from industry experts, and more",
  },
  {
    title: "Collaboration",
    text: "Connect with fellow artists, producers, and industry insiders. Global Breakout is not just a competition; it's a community that fosters collaboration and growth",
  },
  {
    title: "Feedback",
    text: "Receive valuable feedback from experienced judges who understand the music industry inside out. Use their insights to refine your craft and reach new heights",
  },
];
