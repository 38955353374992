import React from "react";
import "./Home.css";

import x from "../../assets/icons/x.svg";
import fb from "../../assets/icons/fb.svg";
import discord from "../../assets/icons/discord.svg";
import ln from "../../assets/icons/ln.svg";
import ig from "../../assets/icons/ig.svg";
import tiktok from "../../assets/icons/tiktok.svg";

// import icn1 from "../../assets/icons/wall-street.svg";
// import icn2 from "../../assets/icons/rolling-stone.svg";
// import icn3 from "../../assets/icons/wired.svg";
// import icn4 from "../../assets/icons/pitchfork.svg";
// import icn5 from "../../assets/icons/guardian.svg";
// import icn6 from "../../assets/icons/hypebeast.svg";
// import icn7 from "../../assets/icons/dazed.svg";
// import icn8 from "../../assets/icons/billboard.svg";
// import icn9 from "../../assets/icons/id.svg";
// import icn10 from "../../assets/icons/newyorker.svg";
// import icn11 from "../../assets/icons/v-magzine.svg";
// import icn12 from "../../assets/icons/beat.png";
// import icn13 from "../../assets/icons/amstel.png";
// import icn14 from "../../assets/icons/pulse.png";
// import icn15 from "../../assets/icons/microloop.png";

import round from "../../assets/images/account.jpg";
import realTime from "../../assets/images/image.svg";
import keepPhone from "../../assets/images/social.jpeg";
import detailedPersona from "../../assets/images/hack.jpg";
// import screenFree from "../../assets/icons/screen-free-sleep-with-smart-speakers.svg";
// import automateYour from "../../assets/icons/automate-your-day-with-routine.jpg";

import bubble from "../../assets/images/data.jpg";

import heroBanner from "../../assets/images/laptop.jpg";
import AppButton from "../../components/button/AppButton";
import Header from "../../components/header/Header";
import {stats} from "../../constants/constants"

function Home(props) {
  // const links = ["Sleep", "Focus", "Relax", "Work"];
  // const images = [
  //   icn1,
  //   icn2,
  //   icn3,
  //   icn4,
  //   icn5,
  //   icn6,
  //   icn7,
  //   icn8,
  //   icn9,
  //   icn10,
  //   // icn11,
  //   // icn12,
  //   // icn13,
  //   // icn14,
  //   // icn15,
  // ];

  return (
    <div className="w-full">
      <Header />

      <section className="app-container flex flex-col justify-center py-[100px] text-center">
        <div className="w-full relative z-20">
          <h1 className="text-4xl text-[#ffffff] font-Barlow">
            Create business value with data
          </h1>
          <p className="text-[18px] text-[#ffffff] my-[30px] w-full md:w-[500px] mx-auto font-Barlow">
            Grow revenue, optimise cost and dominate your market with our
            Operating System for data driven African SMEs
          </p>
          <AppButton className="border-[2px] border-[#ffffff] w-fit mx-auto font-Barlow">
            Request Demo
          </AppButton>
        </div>

        <div className="w-full -mt-[30px] relative z-10">
          <img src={heroBanner} alt="" />
        </div>

        {/* <div className="flex flex-wrap">
          {images.map((url) => {
            return (
              <div className="w-[33%] md:w-[20%] px-1 md:px-3 py-4">
                <img
                  src={url}
                  className="w-full opacity-45 hover:opacity-100 cursor-pointer"
                  alt=""
                />
              </div>
            );
          })}
        </div> */}
      </section>

      {/* <section className="app-container starry-night md:h-[600px] bg-no-repeat bg-cover">
        <div className="w-full flex justify-center relative z-10">
          <div className="w-[90%] mx-auto md:w-[350px] relative bg-[#000000] h-[500px]">
            <img
              src={iphone}
              alt=""
              className="w-[90%] md:w-full absolute left-0 top-0 z-10"
            />
            <video
              className="w-[80%] md:w-[calc(100%-30px)] absolute left-[15px] top-[90px] z-20"
              playsinline=""
              loop=""
              preload="metadata"
              disablepictureinpicture=""
              src="https://endel.io/soundscapes/relax/demo.mp4"
            ></video>
          </div>
        </div>

        <div className="w-full grid md:grid-cols-4 grid-cols-1 gap-x-[30px] relative mt-[70px] bg-[#000000] pt-[0px] z-20 shadow-2xl shadow-full md:w-[#000000]">
          {playlist.map((item) => {
            return (
              <div className="bg-[#000000] p-6 relative gradient-1 rounded-t-3xl">
                <button className="w-[50px] h-[50px] flex justify-center items-center rounded-[100%] bg-[#262626] p-2 absolute right-[20px] -top-[24px]">
                  <img src={play} alt="" />
                </button>
                <h3 className="text-[#ffffff] text-[20px] md:text-[24px] font-Barlow">
                  {item.title}
                </h3>
                <p className="text-[#bfbfbf] text-[14px] md:text-[18px] mt-4 font-Barlow">
                  {item.text}
                </p>

                <p className="text-[#bfbfbf] text-[18px] mt-8 font-Barlow">
                  Learn more ↗
                </p>
              </div>
            );
          })}
        </div>
      </section> */}

      <section className="app-container h-fit md:py-[50px] md:!mt-[50px] px-4 md:px-0">
        <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px] font-Barlow">
          Building A Data Driven Africa
        </h3>
        <p className="text-center text-[#bfbfbf] mt-[30px] w-full md:w-[600px] text-[16px] md:text-[20px] mx-auto font-Barlow">
          We are dedicated to empowering African businesses to harness the power
          of data. We specialize in creating cutting-edge software solutions and
          services designed to make your business data-driven and data-effective
        </p>

        <div className="flex flex-wrap justify-between mt-6">
          {stats.map((stat) => (
            <div className="w-1/2 md:w-1/4">
              <img src={stat.icon} alt="" />
              <p className="text-[#bfbfbf] text-[12px] md:text-[18px] mt-2 text-center mx-2">
                {stat.title}
              </p>
            </div>
          ))}
        </div>

        {/* <div className="w-full md:w-[600px] mx-auto px-4 md:px-0">
          <p className="text-[#bfbfbf] text-[12px] md:text-[14px] mt-6 text-center">
            1) *Read the Whitepaper on Bioarxiv↗
          </p>
          <p className="text-[#bfbfbf] text-[12px] md:text-[14px] mt-6 text-center">
            2) Survey methodology: The Experience Sampling Method provided by
            Mihaly Csikszentmihalyi (Professor of Psychology and Management at
            Claremont Graduate University, author of the best-selling book Flow)
          </p>
        </div> */}
      </section>

      <section className="app-container px-4 md:px-0">
        <div className="w-full md:w-[700px] mx-auto mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px] font-Barlow">
            Data Driven Solutions for every business
            {/* <div className="block">Operating System</div> */}
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto">
            Growing a data driven business isn’t easy, but we’ve got your back.
            Explore some of our customers’ top business challenges and learn how
            Bliu Interactive’s integrated software and solutions can help you
            leave these problems in the past.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[40px] pt-[70px]">
          <div>
            <a href="https://pollish.app" target="_blank" rel="noreferrer">
              <img src={realTime} alt="" className="w-full rounded-[30px]" />

              <div className="gradient-1 rounded-t-[30px] p-[30px]">
                <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                  Conversation Led Market Research - Pollish
                </h3>
                <p className="text-[16px] md:text-[20px] text-[#bfbfbf] font-Barlow">
                  Say hello to a more engaging and insightful market research
                  experience. Our conversational approach taps into authentic
                  dialogues, providing a deeper localized understanding of
                  market dynamics
                </p>
              </div>
            </a>
          </div>
          <div>
            <a href="https://useventapp.com" target="_blank" rel="noreferrer">
              <img src={round} alt="" className="w-full rounded-[30px]" />

              <div className="gradient-1 rounded-t-[30px] p-[30px]">
                <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                  Unlock the Power of Your Small Business Finances - Vent
                </h3>
                <p className="text-[16px] md:text-[20px] text-[#bfbfbf] font-Barlow">
                  Embark on a journey towards financial success and together,
                  we'll revolutionize the way you manage your finances and pave
                  the way for your business to thrive.
                </p>
              </div>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[40px] pt-[70px]">
          <div>
            <a href="https://roe.bliuinteractive.com/" target="_blank" rel="noreferrer">
              <img
                src={detailedPersona}
                alt=""
                className="w-full rounded-[30px]"
              />

              <div className="gradient-1 rounded-t-[30px] p-[30px]">
                <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                  Customer facing AI Fraud Prevention system - Roe
                </h3>
                <p className="text-[16px[ md:text-[20px] text-[#bfbfbf] font-Barlow">
                  Introducing our cutting-edge Customer-Facing AI Fraud
                  Prevention System, designed to safeguard transactions and
                  protect financial well-being. Powered by advanced artificial
                  intelligence and machine learning algorithms, this system is
                  your vigilant partner in detecting and preventing fraudulent
                  activities in real-time.
                </p>
              </div>
            </a>
          </div>
          <div>
            <a href="https://microloop.app" target="_blank" rel="noreferrer">
              <img src={keepPhone} alt="" className="w-full rounded-[30px]" />

              <div className="gradient-1 rounded-t-[30px] p-[30px]">
                <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                  Grow your business socially with organic visibility and brand
                  trust - MicroLoop
                </h3>
                <p className="text-[16px[ md:text-[20px] text-[#bfbfbf] font-Barlow">
                  Discover the magic of data driven seamless brand connections,
                  resonating deeply with your audience for maximum returns and
                  lasting impact.
                </p>
              </div>
            </a>
          </div>
        </div>
      </section>

      {/* <section className="app-container px-4 md:px-0">
        <div className="w-full md:w-[700px] mx-auto mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px] font-Barlow">
            What’s new at Bliu Interactive
            <div className="block">For The Globe.</div>
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto font-Barlow">
            All things current & happening
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[40px] pt-[70px]">
          <div>
            <img src={screenFree} alt="" className="w-full rounded-[30px]" />

            <div className="gradient-1 rounded-t-[30px] p-[30px]">
              <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                Lorem ipsum
              </h3>
              <p className="text-[16px] md:text-[20px] text-[#bfbfbf]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </div>
          <div>
            <img src={automateYour} alt="" className="w-full rounded-[30px] " />

            <div className="gradient-1 rounded-t-[30px] p-[30px]">
              <h3 className="text-[30px] md:text-[40px] text-white mb-4 font-Barlow">
                Automate your <div className="block">day with Routine</div>
              </h3>
              <p className="text-[16px] md:text-[20px] text-[#bfbfbf] font-Barlow">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="px-4 md:px-0">
        <div className="w-full md:w-[700px] mx-auto mt-[150px] md:mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px]">
            Endel on desktop.
            <div className="block">Work, rest, and play.</div>
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto">
            Create a special place for productivity with the desktop Endel app.
            Get a boost of up to 7x increase in sustained Focus with our
            scientifically-validated soundscapes.
          </p>
        </div>

        <div className="w-full md:w-[500px] mx-auto mt-[30px]">
          <img
            src={endForMac}
            className="w-full rounded-t-[30px] overflow-hidden"
            alt=""
          />

          <div className="p-[30px] gradient-1">
            <h3 className="text-white mx-auto text-[30px] md:text-[40px]">
              Endel for Mac
            </h3>
            <p className="text-[#bfbfbf] text-[16px] md:text-[20px]">
              Fast, efficient, and designed to minimize distractions. Endel for
              Mac comes with a streamlined and discreet interface, helping you
              to stay focused on the task at hand.
            </p>
          </div>
        </div>

        <div className="w-full md:w-[700px] mx-auto mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px]">
            Join The Movement
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto">
            Global Breakout is more than a competition; it's a movement that
            celebrates diversity, creativity, and the power of music to unite
            people worldwide. Whether you're a seasoned musician or a rising
            star, Global Breakout welcomes you to share your passion, chase your
            dreams, and make your mark on the global music scene.
          </p>
        </div>
      </section> */}

      {/* <section className="w-full py-[80px] px-4 md:px-0">
        <img src={covers} alt="" />
      </section> */}

      <section className="px-4 md:px-0">
        <div className="w-full md:w-[700px] mx-auto mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px] font-Barlow">
            Grow better with a data driven approach
            <div className="block font-Barlow">with Bliu Interactive today</div>
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto font-Barlow">
            Effective solutions that users readily embrace, utilize to their
            fullest potential, and develop a deep affection for.
          </p>
        </div>

        {/* <div className="app-container flex flex-wrap pt-[50px]">
          <div className="w-full md:w-1/3 flex text-center justify-between px-4 mb-4">
            <img src={flower} alt="" />
            <div className="px-[30px] flex items-center">
              <p className="text-[16px] text-white">
                Apple Watch App of the Year 2020
              </p>
            </div>
            <img src={flower2} alt="" />
          </div>
          <div className="w-full md:w-1/3 flex text-center justify-between px-4 mb-4">
            <img src={flower} alt="" />
            <div className="px-[30px] flex items-center">
              <p className="text-[16px] text-white">
                Golden Kitty Awards Health Fitness Product of the Year 2022
              </p>
            </div>
            <img src={flower2} alt="" />
          </div>
          <div className="w-full md:w-1/3 flex text-center justify-between px-4 mb-4">
            <img src={flower} alt="" />
            <div className="px-[30px] flex items-center">
              <p className="text-[16px] text-white">
                Google Play Best of 2021 Award
              </p>
            </div>
            <img src={flower2} alt="" />
          </div>
        </div> */}

        {/* <div className="app-container grid grid-cols-1 md:grid-cols-3 gap-x-[30px] py-[30px]">
          <div>
            {reviews
              .slice(0, Math.floor(reviews.length / 3))
              .map((review, i) => (
                <Review review={review} key={i} />
              ))}
          </div>
          <div>
            {reviews
              .slice(
                Math.floor(reviews.length / 3),
                Math.floor((2 * reviews.length) / 3)
              )
              .map((review, i) => (
                <Review review={review} key={i} />
              ))}
          </div>
          <div>
            {reviews
              .slice(
                Math.floor((2 * reviews.length) / 3),
                Math.floor(reviews.length)
              )
              .map((review, i) => (
                <Review review={review} key={i} />
              ))}
          </div>
        </div> */}
      </section>

      <section className="px-4 md:px-0">
        <img
          src={bubble}
          className="w-full md:w-[700px] mx-auto mt-20"
          alt=""
        />
        <div className="w-full md:w-[700px] mx-auto mt-[120px]">
          <h3 className="text-center text-white mx-auto text-[30px] md:text-[40px] font-Barlow">
            Join Bliu Interactive on Telegram.
          </h3>
          <p className="text-center text-[#bfbfbf] mt-[30px] w-full text-[16px] md:text-[20px] mx-auto font-Barlow">
            You'll gain access to exclusive news, pro tips on how to get better,
            and a lot more!
          </p>
          <div className="flex justify-center mt-5">
            <AppButton className="mx-auto border-[2px] border-[#ffffff] w-full md:w-[320px] hover:bg-[#a5a5a5] hover:border-[#a5a5a5] font-Barlow">
              About Community
            </AppButton>
          </div>
        </div>
      </section>

      <footer className="">
        <div className="app-container px-[30px] !mt-[100px] flex flex-wrap">
          <div className="w-full md:w-1/2 flex flex-wrap">
            <div className="w-full md:w-1/6 mb-6">
              {/* <img src={logo} alt="" /> */}
            </div>
            <div className="w-full md:w-1/4 mb-6">
              <p className="text-[14px] text-[#bfbfbf] font-Barlow">Explore</p>
              <ul className="text-[16px] text-[#ffffff] mt-2 font-Barlow">
                <li>Data Privacy</li>
                <li>Compliance</li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-6">
              <p className="text-[14px] text-[#bfbfbf] font-Barlow">About</p>
              <ul className="text-[16px] text-[#ffffff] mt-2 font-Barlow">
                <li>Community</li>
                <li>Blog</li>
              </ul>
            </div>
            <div className="w-full md:w-1/4 mb-6">
              <p className="text-[14px] text-[#bfbfbf]">Offers</p>
              <ul className="text-[16px] text-[#ffffff] mt-2">
                <li>Anti-Money Laundering</li>
                <li>Anti-Fraud</li>
                <li>Data Protection</li>
                <li>Operating System</li>
                <li>Financial Services</li>
                <li>Hospital Operations</li>
                <li>Intelligence</li>
                <li>Retail</li>
                <li>Secure Collaboration</li>
                <li>Utilities</li>
              </ul>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <div className="w-full md:w-[350px] -mt-3 float-right">
              <h3 className=" text-white mx-auto text-[24px] md:text-[30px] mt-[20px] md:mt-0 font-Barlow">
                Bliu Interactive Newsletter
              </h3>
              <p className=" text-[#bfbfbf] mt-[12px] w-full text-[14px] md:text-[16px] mx-auto font-Barlow">
                Subscribe to be first to hear about new updates and features.
                Read stories about the Bliu Interactive world plus tips and
                advice.
              </p>

              <div className="mt-[30px]">
                <label className="text-[#bfbfbf] font-Barlow" htmlFor="email">
                  Email
                </label>
                <input
                  type="text"
                  className="border-[2px] border-[#808080] bg-transparent w-full h-[60px] px-[20px] focus:outline-none rounded-[12px] text-white text-[20px] font-Barlow"
                />
                <AppButton className="mx-auto w-full mt-4 hover:bg-[#a5a5a5] hover:border-[#a5a5a5] font-Barlow">
                  Subscribe
                </AppButton>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[30px] mt-[50px] border-t-[1px] border-[#191919]">
          <div className="app-container flex flex-wrap justify-between px-4 md:px-0">
            <div className="flex flex-wrap w-full md:w-fit text-[#bfbfbf] text-[14px]">
              <div className="mb-2 md:mb-0 md:mr-4 w-1/2 md:w-fit font-Barlow">
                Bliu Interactive © 2024
              </div>
              <div className="mb-2 md:mb-0 md:mr-4 w-1/2 md:w-fit font-Barlow">
                Privacy Policy
              </div>
              <div className="mb-2 md:mb-0 md:mr-4 w-1/2 md:w-fit font-Barlow">
                Terms
              </div>
              <div className="mb-2 md:mb-0 md:mr-4 w-1/2 md:w-fit font-Barlow">
                Support
              </div>
            </div>
            <div className="flex w-full md:w-fit mt-4">
              <div className="mr-6 md:ml-6">
                <img src={x} alt="" />
              </div>
              <div className="mr-6 md:ml-6">
                <img src={fb} alt="" />
              </div>
              <div className="mr-6 md:ml-6">
                <img src={ig} alt="" />
              </div>
              <div className="mr-6 md:ml-6">
                <img src={ln} alt="" />
              </div>
              <div className="mr-6 md:ml-6">
                <img src={tiktok} alt="" />
              </div>
              <div className="mr-6 md:ml-6">
                <img src={discord} alt="" />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
