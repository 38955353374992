import React from "react";
import AppButton from "../button/AppButton";
import logo from "../../assets/images/logo.png";
// import { useNavigate } from "react-router-dom";

function Header(props) {
  //  const navigation = useNavigate();
  // const links = ["Sleep", "Focus", "Relax", "Work"];
  return (
    <header className="fixed w-full z-50 header px-4 md:px-0">
      <div className="flex justify-between items-center h-[90px] app-container">
        <div className="w-[150px]">
          <img src={logo} alt="" />
        </div>

        <div className="w-[400px] flex">
          {/* {links.map((link) => (
          <div className="w-[100px] text-[#ffffff] text-[18px] text-center">
            {link}
          </div>
        ))} */}
        </div>

        <div className="w-[400px] flex justify-end">
          {/* <AppButton
            onClick={() => navigation("/auth")}
            className={"white-glow"}
          >
            Join the Movement
          </AppButton> */}
          <AppButton className={"ml-[20px] font-Barlow"}>Request Demo</AppButton>
        </div>
      </div>
    </header>
  );
}

export default Header;
