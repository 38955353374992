import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
function Roe(props) {

  useEffect(()=> {
    window.open('https://roe.bliuinteractive.com')
  }, [])
 

  return (
    <div className="w-full px">
      <header className="w-full fixed top-0 left-0 z-50 px-4 md:px-0">
        <div className="app-container flex justify-between h-[80px] items-center">
          <div className="w-[120px]">
            <img src={logo} alt="" />
          </div>
          <div className="w-[70%] md:w-[300px] bg-[#808080] rounded-[10px] h-[10px]">
            <div
              style={{ width: `${(1 / 3) * 100}%` }}
              className={`bg-[#ffffff] rounded-[10px] h-full`}
            ></div>
          </div>
          <div className="w-[0px] md:w-[120px]"></div>
        </div>
      </header>
     
    </div>
  );
}

export default Roe;
